import React from 'react';
import { Link } from 'gatsby';

interface BlogPostProps {
    pageContext: {
        title: string
        slug: string
    };
}

const BlogPostTemplate: React.FC<BlogPostProps> = ({ pageContext }) => {
    const { title, slug } = pageContext;
    return (<>
        <div>You are viewing {title} located at {slug}</div>
        <div>Go <Link to='/'>Home</Link></div>
    </>);

};

export default BlogPostTemplate;
